<template>
  <div class="container_box details_box_styles">
    <!--返回按钮-->
    <div class="go_back" @click="goBackBtn">
      <Icon class="back_icon" type="ios-arrow-back"/>
      <span class="back_txt">{{ $t('key1001798') }}</span>
    </div>
    <!--步骤条-->
    <div class="basic_information_box">
      <Card :bordered="false" dis-hover>
        <h2 class="title_center">{{ $t('key1003347') + (detailsData.packageCode || '') }}</h2>
        <div class="steps_box">
          <Steps :current="currentStatus">
            <Step
              v-for="(item, index) in stepsList"
              :key="index"
              :title="item.title"
              :icon="item.icon"
              :content="item.time">
            </Step>
          </Steps>
        </div>
      </Card>
    </div>
    <!--主信息-->
    <div class="basic_information_box">
      <Card :bordered="false" dis-hover>
        <template #title>
          <h2 class="title_box">{{ $t('key1003344') }}</h2>
        </template>
        <Row type="flex" :gutter="gutterItem">
          <Col :span="ItemCol" class="basic_item" v-for="(item, index) in masterInfoList" :key="index">
            <span class="basic_title">{{ item.name }}</span>
            <span class="basic_txt">{{ detailsData[item.paramsKey] || '-' }}</span>
          </Col>
        </Row>
      </Card>
    </div>
    <!--sku信息-->
    <div class="basic_information_box">
      <Card :bordered="false" dis-hover>
        <template #title>
          <div class="flex justify-content-between align-items-center">
            <h2 class="title_box">{{ $t('key1003391') }}</h2>
            <Select v-model="pageParams.type" transfer filterable style="width: 150px;" @on-change="changeType">
              <Option v-for="item in typeList" :key="item.value" :value="item.value">{{ item.title }}</Option>
            </Select>
          </div>
        </template>
        <div class="table_box">
          <Table
            highlight-row
            border
            max-height="520"
            :loading="tableLoading"
            :columns="tableColumns"
            :data="tableData">
          </Table>
          <Page
            v-if="!tableLoading"
            :total="total"
            @on-change="changePage"
            show-total
            :page-size="pageParams.pageSize"
            show-elevator
            :current="pageParams.pageNum"
            show-sizer
            @on-page-size-change="changePageSize"
            placement="top"
            :page-size-opts="pageArray">
          </Page>
        </div>
      </Card>
    </div>
    <!--操作日志-->
    <div class="basic_information_box">
      <Card :bordered="false" dis-hover>
        <template #title>
          <h2 class="title_box">{{ $t('key1000215') }}</h2>
        </template>
        <Table
          highlight-row
          border
          max-height="450"
          :loading="tableLoading"
          :columns="operationLogColumns"
          :data="operationLogTableData">
        </Table>
      </Card>
    </div>
    <!--打印条码弹窗-->
    <printSkuBarcodeModal ref="printSkuBarcode"></printSkuBarcodeModal>
  </div>
</template>

<script>
import { alias2f085dd487c1465383484787b29fef2c } from '@/customFolder/customVueAlias.js';

import Mixin from "@/components/mixin/common_mixin";
import api from '@/api/';
import {DELIVERYORDERTYPE} from "@/utils/enum";
import {commonCopyContent, getUserNameByUserId, setConfigCharacters} from "@/utils/common";
import printSkuBarcodeModal from "@/views/supplier/components/supplierOrder/printSkuBarcodeModal";

export default {
  name: "stockOrderDetails",
  mixins: [Mixin],
  data() {
    let v = this;
    return {
      total: 0,
      pageParams: {
        packageId: this.$route.query.packageId,
        type: 1,
        pageNum: 1,
        pageSize: 15,
      },
      detailsData: {},
      currentStatus: 0,
      ItemCol: 6,
      stepsList: [],
      masterInfoList: [
        {name: alias2f085dd487c1465383484787b29fef2c.t('key1003294'), paramsKey: 'packageCode'},
        {name: alias2f085dd487c1465383484787b29fef2c.t('key1003392'), paramsKey: 'outboundTypeText'},
        {name: alias2f085dd487c1465383484787b29fef2c.t('key1002722'), paramsKey: 'merchantName'},
        {name: alias2f085dd487c1465383484787b29fef2c.t('key1002723'), paramsKey: 'merchantId'},
        {name: alias2f085dd487c1465383484787b29fef2c.t('key1003174'), paramsKey: 'pickupOrderNumber'},
        {name: alias2f085dd487c1465383484787b29fef2c.t('key1001069'), paramsKey: 'shippingMethod'},
        {name: alias2f085dd487c1465383484787b29fef2c.t('key1001074'), paramsKey: 'expressageNo'},
        {name: alias2f085dd487c1465383484787b29fef2c.t('key1000834'), paramsKey: 'createdTime'},
        {name: alias2f085dd487c1465383484787b29fef2c.t('key1003393'), paramsKey: 'storageTime'},
        {name: alias2f085dd487c1465383484787b29fef2c.t('key1003175'), paramsKey: 'pickingEndTime'},
      ], // 主信息基础数据
      typeList: [
        {title: alias2f085dd487c1465383484787b29fef2c.t('key1003394'), value: 1},
        {title: alias2f085dd487c1465383484787b29fef2c.t('key1003395'), value: 2}
      ],
      tableColumns: [
        {
          title: alias2f085dd487c1465383484787b29fef2c.t('key1000205'),
          key: 'skuInfo',
          align: 'left',
          minWidth: 350,
          render(h, params) {
            if (params.row._productInfo) {
              let supplierSku = params.row._productInfo.sku || ''; // 供应商sku
              let ymsSku = params.row._productInfo.ymsSku || ''; // 云卖sku
              let productGoodsSpecifications = params.row._productInfo.productGoodsSpecifications || [];
              let spec = productGoodsSpecifications.map(i => i.name + '：' + i.value).join('、');
              let skuImgPath = params.row._productInfo.primaryImage;
              let spuImgPath = params.row._productInfo._spuInfo.primaryImage;
              let imgPath = skuImgPath ? skuImgPath : spuImgPath;
              let list = [
                v.tableImg(h, params, null, imgPath),
                h('div', {
                  class: 'text-align-left pl10'
                }, [
                  h('Alink', {
                    props: {
                      linkName: params.row._productInfo._spuInfo.name,
                      textAlign: 'left',
                      className: 'mb5',
                      AlinkClass: 'ellipsis_1',
                      AlinkShow: true
                    },
                    on: {
                      AlinkBtn: () => {
                        const {href} = v.$router.resolve({
                          path: '/productDetails',
                          query: {
                            type: 'look',
                            productId: params.row.productGoodsId
                          }
                        });
                        window.open(href, '_blank');
                      }
                    }
                  }),
                  h('p', {class: 'flex align-items-center mb5'}, [
                    h('span', {style: {color: '#000'}}, alias2f085dd487c1465383484787b29fef2c.t('key1003176') + supplierSku),
                    supplierSku ? h('Icon', {
                      props: {
                        type: 'ios-copy-outline',
                        size: 18,
                        color: '#01a6ee'
                      },
                      style: {
                        marginLeft: '10px',
                        cursor: 'pointer'
                      },
                      attrs: {
                        title: alias2f085dd487c1465383484787b29fef2c.t('key1000000')
                      },
                      on: {
                        click: () => {
                          commonCopyContent(supplierSku, alias2f085dd487c1465383484787b29fef2c.t('key1000004'));
                        }
                      }
                    }) : ''
                  ]),
                  h('p', {class: 'flex align-items-center mb5'}, [
                    h('span', {style: {color: '#000'}}, `${setConfigCharacters}SKU：` + ymsSku),
                    ymsSku ? h('Icon', {
                      props: {
                        type: 'ios-copy-outline',
                        size: 18,
                        color: '#01a6ee'
                      },
                      style: {
                        marginLeft: '10px',
                        cursor: 'pointer'
                      },
                      attrs: {
                        title: alias2f085dd487c1465383484787b29fef2c.t('key1000000')
                      },
                      on: {
                        click: () => {
                          commonCopyContent(ymsSku, alias2f085dd487c1465383484787b29fef2c.t('key1000004'));
                        }
                      }
                    }) : ''
                  ]),
                  h('div', {class: 'flex align-items-center'}, [
                    h('p', {
                      style: {
                        width: '70px',
                        textAlign: 'right'
                      }
                    }, alias2f085dd487c1465383484787b29fef2c.t('key1000206')),
                    h('p', {
                      style: {
                        flex: 1
                      },
                      class: 'color-green'
                    }, spec),
                  ])
                ])
              ];
              return h('div', {
                class: 'flex ptb10',
              }, list)
            }
          }
        },
        {
          title: alias2f085dd487c1465383484787b29fef2c.t('key1003396'),
          minWidth: 130,
          key: 'quantity',
          align: 'left',
        },
        {
          title: alias2f085dd487c1465383484787b29fef2c.t('key1003397'),
          minWidth: 130,
          key: 'actualSortingNumber',
          align: 'left',
          render(h, params) {
            let quantity = params.row.quantity || 0;
            let actualSortingNumber = params.row.actualSortingNumber || 0;
            let talg = quantity !== actualSortingNumber;
            let pickingEndTime = v.detailsData.pickingEndTime;
            if (pickingEndTime) {
              return h('Alink', {
                props: {
                  linkName: actualSortingNumber,
                  textAlign: 'left',
                  AlinkShow: false,
                  styles: {
                    color: talg ? '#D9001B' : ''
                  }
                },
              })
            } else {
              return h('span', '-')
            }
          }
        },
        {
          title: alias2f085dd487c1465383484787b29fef2c.t('key1000278'),
          width: 100,
          key: 'options',
          align: 'left',
          render(h, params) {
            return h('optsButtonList', {
              props: {
                operationList: [
                  {
                    text: alias2f085dd487c1465383484787b29fef2c.t('key1000407'),
                    show: true,
                    clickFn: () => {
                      if (v.detailsData.outboundType === 2) {
                        v.$refs['printSkuBarcode'].initPrintSkuBarcodeData(params.row)
                      } else {
                        v.$Message.warning(`${alias2f085dd487c1465383484787b29fef2c.t('key1005399')}${setConfigCharacters}${alias2f085dd487c1465383484787b29fef2c.t('key1005400')}`);
                        return false;
                      }
                    }
                  }
                ]
              },
              on: {
                setWidth(value) {
                  if (value) {
                    v.optionBtnWidth.push(value);
                    params.column.width = Math.max(...v.optionBtnWidth);
                    v.$forceUpdate();
                  }
                }
              }
            });
          }
        }
      ],
      tableData: [],
      operationLogColumns: [
        {
          title: alias2f085dd487c1465383484787b29fef2c.t('key1000219'),
          key: 'logContentDesc',
          minWidth: 400,
          align: 'left'
        },
        {
          title: alias2f085dd487c1465383484787b29fef2c.t('key1000220'),
          key: 'updatedTime',
          width: 200,
          align: 'center',
          render: (h, params) => {
            return h('div', v.$uDate.getDataToLocalTime(params.row.updatedTime, 'fulltime'));
          }
        },
        {
          title: alias2f085dd487c1465383484787b29fef2c.t('key1000221'),
          key: 'updatedBy',
          width: 200,
          align: 'center',
          render: (h, params) => {
            return h('div', getUserNameByUserId(params.row.updatedBy, v));
          }
        }
      ],
      operationLogTableData: [],
    }
  },
  created() {
    this.initFun();
  },
  methods: {
    // 初始化接口数据
    initFun() {
      let v = this;
      let packageId = this.$route.query.packageId;
      (async () => {
        await v.getUserName()
        await v.getDetailsData(packageId);
        await v.getSkuInfoData();
      })();
    },
    // 返回列表
    goBackBtn() {
      this.$router.push('/stockList');
    },
    // 获取详情数据
    getDetailsData(packageId) {
      let v = this;
      v.axios.get(api.get_packageInfo_stock + packageId, {loading: true}).then((response) => {
        if (response.data.code === 0) {
          let data = response.data.datas;
          if (data) {
            let timerKeyList = ['createdTime', 'pickingEndTime', 'despatchTime', 'storageTime'];
            timerKeyList.map((key) => {
              data[key] = data[key] ? v.$uDate.dealTime(data[key], 'fulltime') : '';
            });
            DELIVERYORDERTYPE.map((item) => {
              if (item.value === data.outboundType) {
                data.outboundTypeText = item.title;
              }
            });

            v.stepsList = [
              {status: 0, icon: 'md-cash', title: alias2f085dd487c1465383484787b29fef2c.t('key1001161'), time: data.createdTime},
              {status: 1, icon: 'md-car', title: alias2f085dd487c1465383484787b29fef2c.t('key1001166'), time: data.despatchTime},
              {status: 2, icon: 'md-cube', title: `${setConfigCharacters}${alias2f085dd487c1465383484787b29fef2c.t('key1003501')}`, time: data.pickingEndTime}
            ];
            v.stepsList.map((item) => {
              if (item.time) {
                v.currentStatus = item.status;
              }
            });
            v.operationLogTableData = data.logList || [];
            v.detailsData = data;
          }
        }
      });
    },
    // 获取sku信息列表数据
    getSkuInfoData() {
      let v = this;
      v.tableData = [];
      v.axios.post(api.post_packageInfo_queryPackageGoodsById, v.pageParams).then((response) => {
        if (response.data.code === 0) {
          let data = response.data.datas;
          if (data) {
            let list = data.list || [];
            if (list.length > 0) {
              let productGoodsIds = [];
              list.map((item) => {
                productGoodsIds.push(item.productGoodsId);
              });
              if (productGoodsIds.length > 0) {
                (async () => {
                  this.tableData = await this.getProductInfoByIds(productGoodsIds, list, false,
                    false, false, api.post_productInfo_queryByProductGoodsIds, 'supplier');
                })();
              } else {
                v.tableData = list;
              }
            }
            v.total = data.total;
          }
        }
      });
    },
    // 切换筛选
    changeType() {
      this.getSkuInfoData();
    }
  },
  components: {
    printSkuBarcodeModal
  }
}
</script>

<style lang="less" scoped>
.details_box_styles {
  .basic_information_box {
    .steps_box {
      width: 60%;
    }
  }
}
</style>